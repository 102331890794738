body {
  padding: 0;
  font-size: 0.80em;
  font-family: -apple-system, "Thasadith", BlinkMacSystemFont,  "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #D1DBF2;
}
h1 {
  font-size: 1.1em;
  font-weight: 500;
  color: #363263;
  text-shadow: 0px 0px 30px #99b8ff;
}

#header {
  text-align: center;
}

.container {
  width: 1100px;
  min-height: 800px;
  margin-left: auto;
  margin-right:auto;
  margin-bottom:50px;
  
}
.powerball-change-table {
    border-collapse: collapse;
    width: 450px;
}

.powerball-change-table, .powerball-change-table-th, .powerball-change-table-td {
    border: 1px solid black;
    padding: 7px;
    font-size: 1em;
}
span.checkFilterResultDetail-title:hover {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.checkFilterResultDetail-table {
    border-collapse: collapse;
    width: 450px;
}

.checkFilterResultDetail, .checkFilterResultDetail-th, .checkFilterResultDetail-td {
    border: 1px solid black;
    padding: 7px;
    font-size: 0.8em;
}
