span.checkFilterResultDetail-title:hover {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.checkFilterResultDetail-table {
    border-collapse: collapse;
    width: 450px;
}

.checkFilterResultDetail, .checkFilterResultDetail-th, .checkFilterResultDetail-td {
    border: 1px solid black;
    padding: 7px;
    font-size: 0.8em;
}